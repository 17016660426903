(function ($, dell) {
    dell.ComponentLoader = dell.ComponentLoader || {};
    dell.ComponentLoader.loadFiles = function (url, callback) {
        try {
            if (url !== undefined && url !== null && url !== '') {
                var jsExpr = new RegExp("js$", "i"),
                    cssExpr = new RegExp("css$", "i"),
                    jsonExpr = new RegExp("json$", "i"),
                    splitter = url.split('?')[0],
                    type = null,
                    tag = null;

                if (jsExpr.test(splitter) || jsonExpr.test(splitter)) {
                    type = 'text/javascript';
                } else if (cssExpr.test(splitter)) {
                    type = 'text/css';
                }
                var crossOrigin = "False";
                if (Dell.ComponentLoader.crossOrigin !== undefined && Dell.ComponentLoader.crossOrigin !== null) {
                    crossOrigin = Dell.ComponentLoader.crossOrigin[url];
                }

                switch (type) {
                    case 'text/javascript':
                        tag = document.createElement('script');
                        tag.type = type;
                        tag.src = url;
                        tag.async = true;
                        if (crossOrigin !== undefined && crossOrigin !== null && crossOrigin === "True")
                            tag.crossOrigin = "anonymous";
                        break;
                    case 'text/css':
                        tag = document.createElement('link');
                        tag.rel = 'stylesheet';
                        tag.type = type;
                        tag.href = url;
                        if (crossOrigin !== undefined && crossOrigin !== null && crossOrigin === "True")
                            tag.crossOrigin = "anonymous";
                        break;
                }
                if (callback != null) {
                    if (tag.readyState) { // IE, include IE9
                        tag.onreadystatechange = function () {
                            if (tag.readyState == "loaded" || tag.readyState == "complete") {
                                tag.onreadystatechange = null;
                                callback();
                            }
                        };
                    } else {
                        tag.onload = function () { // Other browsers
                            callback();
                        };
                    }
                }
                var a = document.getElementsByTagName('script')[0];
                a.parentNode.insertBefore(tag, a);
            }
            
        } catch (ex) {
            console.error("Fail to load components file :- " + url, ex);
        }
    };

    $(function () {
        try {
            var lists = dell.ComponentLoader.components;
            if (lists !== undefined && lists !== null) {
                for (var i = 0, j = lists.length; i < j; i++) {
                    dell.ComponentLoader.loadFiles(lists[i].toLowerCase());
                }
            }
        } catch (ex) {
            console.error("Fail ComponentLoader file :- " + url, ex);
        }
    });
})(jQuery, Dell = window.Dell || {});